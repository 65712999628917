import React, { HTMLProps, SyntheticEvent, useMemo, useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { cn } from '@/lib/utils'
import useResponsive from '@/hooks/responsive'
import AccordionForSmallDevices from '@/custom-components/tab-section/AccordionForSmallDevices'
import { SECTION_TABS_DATA } from '@/custom-components/tab-section/constants'
import { motion, AnimatePresence } from 'framer-motion'
import TabContent from '@/custom-components/tab-section/TabContent'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div role='tabpanel' hidden={value !== index} id={`tabpanel-${index}`} {...other}>
      {value === index && <div className={'p-3'}>{children}</div>}
    </div>
  )
}

export interface TabClassNames {
  tab_wrapper?: HTMLProps<HTMLElement>['className']
  body_wrapper?: HTMLProps<HTMLElement>['className']
  parent_content_wrapper?: HTMLProps<HTMLElement>['className']
  content_wrapper?: HTMLProps<HTMLElement>['className']
  content?: HTMLProps<HTMLElement>['className']
  image_wrapper?: HTMLProps<HTMLElement>['className']
  image?: HTMLProps<HTMLElement>['className']
}

interface Props {
  pageKey: string
  className?: string
  tabClassNames?: TabClassNames
}

const HomeTabBoxGenerate = ({ pageKey, className, tabClassNames }: Props) => {
  const { desktopUp } = useResponsive()

  const [value, setValue] = useState(0)

  const tabContents = useMemo(() => SECTION_TABS_DATA?.[pageKey] || [], [pageKey])

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const getIconPosition = (index: number) => {
    if (value !== index) {
      return { iconPosition: 'bottom' } as const
    }
    return {}
  }

  const getTabStyle = (index: number) => {
    const commonStyle = {
      fontFamily: 'var(--font-inter), sans-serif',
      fontSize: '24px',
      fontWeight: '600',
      lineHeight: '26px',
      textTransform: 'none',
      backgroundColor: '#ECF3FE',
      margin: '0px 50px',
      borderRadius: '5px',
      color: '#01306E'
    } as const
    if (value === index) {
      return {
        ...commonStyle,
        fontSize: '24px',
        color: '#FFF',
        backgroundColor: '#2C28A2',
        paddingTop: '16px',
        paddingBottom: '16px'
      }
    }
    return { ...commonStyle }
  }

  const indicatorStyle = {
    // marginBottom: '24px',
    // backgroundColor: '#1678FB'
    fontSize: '1rem',
    backgroundColor: 'transparent'
  }

  return (
    <>
      <div className={cn(`bg-white`, className)}>
        {desktopUp ? (
          <>
            <div className={cn('rounded-lg border-2 border-[#1678FB] px-[13px] py-[10px]', tabClassNames?.tab_wrapper)}>
              <Tabs
                TabIndicatorProps={{ style: indicatorStyle }}
                value={value}
                onChange={handleChange}
                variant='fullWidth'
              >
                {tabContents.map((tab: any, index) => (
                  <Tab
                    key={index}
                    style={getTabStyle(index)}
                    {...getIconPosition(index)}
                    label={tab?.label?.html ? tab?.label.html : tab?.label}
                  />
                ))}
              </Tabs>
            </div>
            <div className={cn('', tabClassNames?.body_wrapper)}>
              {tabContents.map((data, index) => (
                <CustomTabPanel key={index} value={value} index={index}>
                  <AnimatePresence mode='wait'>
                    <motion.div
                      animate={{ opacity: 1, y: 0 }}
                      initial={{ opacity: 0, y: 20 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.9 }}
                    >
                      <TabContent data={data} tabClassNames={tabClassNames} />
                    </motion.div>
                  </AnimatePresence>
                </CustomTabPanel>
              ))}
            </div>
          </>
        ) : (
          <>
            <AccordionForSmallDevices data={tabContents} tabClassNames={tabClassNames} defaultExpanded={0} />
          </>
        )}
      </div>
    </>
  )
}

export default HomeTabBoxGenerate
