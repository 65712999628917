import React from 'react'
import Image from 'next/image'
import 'swiper/css/navigation'
import 'swiper/css/effect-coverflow'

import { cn } from '@/lib/utils'
import News from '@/components/news'
import { genLocalImage } from '@/helpers/utils'
import Meta from '../src/custom-components/Meta'
import HomeProducts from '@/components/home/products'
import HomePayment from '@/components/home/payment'
import HomeBanner from '@/components/home/banner/HomeBanner'
import HomeFeature from '@/components/home/feature/HomeFeature'
import HomeSolution from '@/components/home/solution/HomeSolution'

// Partner Logo
import PlaidLogo from '~/public/assets/images/homepage/v4/plaid-logo.svg'
import NachaLogo from '~/public/assets/images/homepage/v4/nacha-logo.svg'
import StripeLogo from '~/public/assets/images/homepage/v4/stripe-logo.svg'
import ElavonLogo from '~/public/assets/images/homepage/v4/elavon-logo.svg'
import ApptechLogo from '~/public/assets/images/homepage/v4/apptech-logo.svg'

export const partners = [
  {
    thumb: ApptechLogo,
    url: 'https://apptechcorp.com/'
  },
  {
    thumb: StripeLogo,
    url: 'https://stripe.com/'
  },
  {
    thumb: PlaidLogo,
    url: 'https://plaid.com/'
  },
  {
    thumb: ElavonLogo,
    url: 'https://www.elavon.com/'
  },
  {
    thumb: NachaLogo,
    url: 'https://www.nacha.org/'
  }
]

const Home = () => {
  return (
    <div className={'ptm-homepage-wr'}>
      <Meta
        title={`Online Business and Invoice Management`}
        description={`PayToMe.co offers innovative and secure payment solutions including text-to-pay, digital invoicing, and digital checkbook services.`}
        keywords={
          'digital payment solutions, online payment processing, secure payments, FinTech platform, merchant services, digital invoicing, text to pay, payment gateway, electronic payments, mobile payment solutions, payment link generator, digital checkbook, payment management, KYC verification, KYB compliance, FinTech innovations'
        }
      />

      <HomeBanner />

      <div data-aos='fade-up' data-aos-delay={300} data-aos-duration={1000} data-aos-easing='ease-in-sine'>
        <HomeFeature />
      </div>

      <div data-aos='fade-up' data-aos-delay={300} data-aos-duration={1000} data-aos-easing='ease-in-sine'>
        <HomeSolution />
      </div>

      <div data-aos='fade-up' data-aos-delay={300} data-aos-duration={1000} data-aos-easing='ease-in-sine'>
        <HomeProducts />
      </div>

      <div data-aos='fade-up' data-aos-delay={300} data-aos-duration={1000} data-aos-easing='ease-in-sine'>
        <section className={cn('bg-indigo-50 py-[30px] lg:py-[60px]')}>
          <News title={'Partners, News & Awards'} />

          <div className='sm:ustify-center container flex w-full max-w-[350px] flex-wrap items-center justify-evenly gap-2 sm:mt-4 sm:items-end sm:gap-5 md:max-w-[600px] lg:max-w-[1250px]'>
            {partners?.map((partner, index) => (
              <a
                href={partner?.url}
                className={`lg:w-[15%] xl:w-[15%] w-[${
                  index === 0 ? '120px' : '90px'
                }] flex items-center justify-center text-center`}
                target={'_blank'}
                key={index}
              >
                <Image
                  src={genLocalImage(partner?.thumb).src}
                  className={'h-[50px] sm:w-full md:h-[55px] lg:h-[65px]'}
                  alt='Partner Logo'
                />
              </a>
            ))}
          </div>
        </section>
      </div>

      <div data-aos='fade-up' data-aos-delay={300} data-aos-duration={1000} data-aos-easing='ease-in-sine'>
        <HomePayment />
      </div>
    </div>
  )
}

export default Home
